@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Raleway", sans-serif;
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0); /* Start and end at the original position */
  }
  50% {
    transform: translateY(-10px); /* Move up by 10px */
  }
}

.animate-bounce-slow {
  animation: moveUpDown 3s ease-in-out infinite;
}
@keyframes circular-motion-clockwise {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(15px, 0);
  }
  50% {
    transform: translate(15px, 15px);
  }
  75% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* Circular motion - counterclockwise */
@keyframes circular-motion-counterclockwise {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-15px, 0);
  }
  50% {
    transform: translate(-15px, -15px);
  }
  75% {
    transform: translate(0, -15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* Random zigzag motion */
@keyframes circular-motion-zigzag {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(15px, -15px);
  }
  50% {
    transform: translate(-15px, 15px);
  }
  75% {
    transform: translate(15px, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* Applying animations */
.animate-circular-clockwise {
  animation: circular-motion-clockwise 10s linear infinite;
}

.animate-circular-counterclockwise {
  animation: circular-motion-counterclockwise 16s linear infinite;
}

.animate-circular-zigzag {
  animation: circular-motion-zigzag 12s linear infinite;
}
/* From Uiverse.io by adeladel522 */
.button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  box-shadow: 0px 1.82px 13.67px 0px #c58ec780;
  background: linear-gradient(
    93.33deg,
    rgba(202, 99, 206, 0.7) 6.24%,
    rgba(120, 49, 122, 0.867107) 88.06%
  );
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  outline: none;
  overflow: hidden;
}

.icon {
  width: 36px;
  height: 36px;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  transform: scale(1.05);
}

.button:hover .icon {
  transform: translate(4px);
}

.button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}
.black-button {
  background-color: black !important;
  background: black;
}
@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animate-marquee {
  animation: marquee 40s linear infinite;
}

.animate-marquee2 {
  animation: marquee2 40s linear infinite;
}

.process-one {
  border-top-right-radius: 150px !important ;
  border-top-left-radius: 30px !important ;
  border-bottom-left-radius: 30px !important ;
  border-bottom-right-radius: 30px !important ;
  box-shadow: 0px 1px 13px 1px rgba(75, 62, 75, 0.54);
  border: 1px solid rgba(202, 99, 206, 0.54);
}
.process-two {
  border-top-right-radius: 30px !important ;
  border-top-left-radius: 30px !important ;
  border-bottom-left-radius: 30px !important ;
  border-bottom-right-radius: 30px !important ;
  box-shadow: 2px 2px 8px 0px rgba(109, 178, 134, 0.25);
}
.process-three {
  border-top-right-radius: 30px !important ;
  border-top-left-radius: 30px !important ;
  border-bottom-left-radius: 30px !important ;
  border-bottom-right-radius: 30px !important ;
  box-shadow: 2px 2px 8px 0px rgba(109, 178, 134, 0.25);
}
.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  bottom: -35px; /* Adjust this value if needed */
  left: 0%;
}

.slick-dots li {
  margin: 0 5px; /* Adjust spacing between dots */
}

.shadow-custom {
  box-shadow: 3px 0px 15px 3px rgba(0, 0, 0, 0.1),
    5px 0px 15px 5px rgba(0, 0, 0, 0.1);
}
.image-adjustment {
  bottom: -100% !important;
}
.slick-dots li.slick-active div {
  background-color: #ca63ce !important;
}
